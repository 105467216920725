<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <h3 class="card-title">Interne Kommunikation</h3>
                    </div>
                    <div class="card-body">
                        <p>Dieses Kommentarfeld dient nur der internen Kommunikation. Das Feld wird zu keiner Zeit im Frontend und für den Kunden sichtbar angezeigt</p>
                        <textarea class="form-control form-control-sm" rows="5" v-model="order.internalcomment" disabled></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <h3 class="card-title">Externe Kommunikation</h3>
                    </div>
                    <div class="card-body">
                        <p>Das nachfolgende Kommentarfeld ist im Kundenkonto für den Kunden sichtbar. Nach dem Speichern ist der Kommentar unmittelbar sichtbar.
                        <div class="form-group row align-items-center">
                            <label for="" class="col-md-2 col-form-label">Kundenkommentar:</label>
                            <div class="col-md-10">
                                <textarea class="form-control form-control-sm" rows="5" v-model="order.customercomment" disabled></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderDetailsCommunication',
    props: [
        'order'
    ],
    data(){
        return  {
        }
    },

    methods:{
        saveInternalComment(){
            this.$Progress.start();
            this.axios
                .put("/orders/" + this.order.id, {
                    'internalcomment': this.order.internalcomment,
                })
                .then(() => {
                    this.$parent.getOrder();
                    this.$Progress.finish();
                    this.$swal({
                        icon: "success",
                        title: "Interner Kommentar gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        saveExternalComment(){
            this.$Progress.start();
            this.axios
                .put("/orders/" + this.order.id, {
                    'customercomment': this.order.customercomment,
                })
                .then(() => {
                    this.$parent.getOrder();
                    this.$Progress.finish();
                    this.$swal({
                        icon: "success",
                        title: "Externer Kommentar gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    }
}
</script>

<style>

</style>